import { useState, useEffect } from "react";
import formatTime from "utils/format-time";

const PendingButton = (props:PendingButtonProps) => {
    const {timeStart,text,callback} = props  
  const [time, settime] = useState(timeStart)
    useEffect(() => {
      const interval = setInterval(() => settime(p => p-1), 1000);
      return () => clearInterval(interval)
    }, [])
    useEffect(() => {
      if (time <= 0 && callback) callback()
    }, [time])
    return (
      <button className="game-button game-button-pending" {...props}>
      <div>
        {text} {formatTime(time)}
      </div>
      </button>
    )
  }
  
  interface PendingButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>{
    timeStart:number;
    text:string
    callback?:() => void
  }
  export default PendingButton