import React from 'react'
import { useNavigate } from 'react-router-dom'
import tg from 'utils/tg'

const Entry = () => {
  const navigate = useNavigate()

  const handleConfirm = () => {
    tg.CloudStorage.setItem('entry','true',() => {
      navigate('/')
    })
  }

  return (
    <div className="enter">
      <div className="enter__contain">
        <h2 className="enter__title">Добро пожаловать</h2>
        <p className="enter__subtitle">Подтвердите доступ к вашим данным.</p>
        <form className="enter__form">
          <div className="enter__string">
            <p className="enter__form-text">Доступ к данным API</p>
            <img src="./img/circle-check.png" className="enter__img" />
          </div>
          <div className="enter__string">
            <p className="enter__form-text">Соглашение</p>
            <img src="./img/circle-check.png" className="enter__img" />
          </div>
          <div className="enter__string">
            <p className="enter__form-text">Подтверждение</p>
            <img src="./img/circle-check.png" className="enter__img" />
          </div>
          <button className="enter__form-button" onClick={handleConfirm}>ПОДТВЕРДИТЬ</button>
        </form>
      </div>
    </div>
  )
}

export default Entry