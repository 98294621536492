import ReactGA from 'react-ga';

export const initGA = (trackingId: string): void => {
  ReactGA.initialize(trackingId);
  console.log('Google Analytics initialized');
};

// Отправка события
export const logEvent = (category: string, action: string, label?: string | undefined): void => {
  if (category && action) {
    ReactGA.event({
      category,
      action,
      label,
    });
  }
};

// Отправка просмотра страницы
export const logPageView = (path: string): void => {
  ReactGA.pageview(path);
};