import React, { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    icon:keyof typeof icons
}

const Icon = (props: Props) => {
  const icon = icons[props.icon]
//   icon.props = props
return icon
}

const icons = {
    home:(
        <svg
          width="31"
          height="30"
          viewBox="0 0 31 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.22729 15.2527C3.22729 12.4159 3.22729 10.9974 3.852 9.82154C4.47671 8.64568 5.61801 7.91589 7.90059 6.45633L10.307 4.91757C12.7199 3.37471 13.9263 2.60327 15.2594 2.60327C16.5924 2.60327 17.7989 3.37471 20.2118 4.91757L22.6182 6.45631C24.9008 7.91589 26.0421 8.64568 26.6668 9.82154C27.2915 10.9974 27.2915 12.4159 27.2915 15.2527V17.1384C27.2915 21.9741 27.2915 24.3921 25.8818 25.8943C24.4722 27.3967 22.2033 27.3967 17.6658 27.3967H12.853C8.31538 27.3967 6.04659 27.3967 4.63694 25.8943C3.2273 24.3921 3.22729 21.9741 3.22729 17.1384V15.2527Z"
            stroke="white"
            strokeWidth="1.5"
          />
          <path
            d="M18.869 22.438H11.6498"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
    ),
    game:(
        <svg
        width="31"
        height="30"
        viewBox="0 0 31 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5 16.25C16.1903 16.25 16.75 15.6903 16.75 15C16.75 14.3096 16.1903 13.75 15.5 13.75C14.8096 13.75 14.25 14.3096 14.25 15C14.25 15.6903 14.8096 16.25 15.5 16.25Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.75 25.25C28.3 22.7125 25.775 16.05 20.125 10.375C14.45 4.72498 7.78748 2.19998 5.24998 4.74998C2.69998 7.28748 5.22498 13.95 10.875 19.625C16.55 25.275 23.2125 27.8 25.75 25.25Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.125 19.625C25.775 13.95 28.3 7.28748 25.75 4.74998C23.2125 2.19998 16.55 4.72498 10.875 10.375C5.22498 16.05 2.69998 22.7125 5.24998 25.25C7.78748 27.8 14.45 25.275 20.125 19.625Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    tasks:(
        <svg
        width="31"
        height="30"
        viewBox="0 0 31 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.4916 10.229L23.9336 9.80554C24.3445 9.41172 24.5499 9.21482 24.6734 9.00998C25.0083 8.45411 25.0083 7.76925 24.6734 7.21338C24.5499 7.00854 24.3445 6.81162 23.9336 6.41782C23.5226 6.024 23.3172 5.8271 23.1034 5.70883C22.5234 5.3879 21.8088 5.3879 21.2287 5.70883C21.015 5.8271 20.8095 6.024 20.3985 6.41782L19.9567 6.84128M14.6541 18.6983L8.02599 25.0503C7.61505 25.4441 7.40959 25.6411 7.19585 25.7593C6.6158 26.0802 5.90117 26.0802 5.32112 25.7593C5.10738 25.6411 4.9019 25.4441 4.49097 25.0503C4.08005 24.6564 3.87457 24.4595 3.75116 24.2547C3.41628 23.6988 3.41628 23.014 3.75116 22.4581C3.87457 22.2533 4.08005 22.0564 4.49097 21.6625L11.1191 15.3106M10.2354 14.4636L15.5379 19.5453C17.6209 21.5414 18.6625 22.5396 19.9567 22.5396C21.2509 22.5396 22.2925 21.5414 24.3754 19.5453C26.4585 17.549 27.5 16.5509 27.5 15.3106C27.5 14.0702 26.4585 13.0721 24.3754 11.0759L19.0729 5.99435C16.9899 3.99812 15.9484 3 14.6541 3C13.3599 3 12.3184 3.99812 10.2354 5.99435C8.15235 7.99059 7.11084 8.9887 7.11084 10.229C7.11084 11.4693 8.15235 12.4675 10.2354 14.4636Z"
          stroke="white"
          strokeWidth="1.5"
        />
      </svg>
    ),
    shop:(
        <svg
        width="27"
        height="26"
        viewBox="0 0 27 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.4324 7.58371C12.9074 6.75911 13.1449 6.3468 13.5 6.3468C13.8551 6.3468 14.0926 6.75911 14.5676 7.58371L14.6905 7.79705C14.8254 8.03137 14.8929 8.14854 14.9981 8.22584C15.1034 8.30315 15.2344 8.33185 15.4965 8.38923L15.7351 8.44149C16.6575 8.64346 17.1187 8.74444 17.2285 9.0859C17.3382 9.42735 17.0237 9.78316 16.395 10.4948L16.2322 10.6789C16.0536 10.8811 15.9642 10.9822 15.924 11.1073C15.8839 11.2323 15.8974 11.3672 15.9244 11.6371L15.949 11.8826C16.044 12.8321 16.0916 13.3068 15.8044 13.5179C15.517 13.7289 15.0852 13.5365 14.2216 13.1517L13.9982 13.0522C13.7527 12.9428 13.6301 12.8881 13.5 12.8881C13.3699 12.8881 13.2472 12.9428 13.0017 13.0522L12.7784 13.1517C11.9147 13.5365 11.483 13.7289 11.1956 13.5179C10.9084 13.3068 10.9559 12.8321 11.051 11.8826L11.0756 11.6371C11.1026 11.3672 11.1161 11.2323 11.076 11.1073C11.0357 10.9822 10.9464 10.8811 10.7677 10.6789L10.605 10.4948C9.97621 9.78316 9.66181 9.42735 9.77153 9.0859C9.88126 8.74444 10.3424 8.64346 11.2649 8.44149L11.5035 8.38923C11.7656 8.33185 11.8966 8.30315 12.0019 8.22584C12.1071 8.14854 12.1746 8.03137 12.3095 7.79705L12.4324 7.58371Z"
          stroke="white"
          strokeWidth="1.5"
        />
        <path
          d="M22.25 9.9758C22.25 14.6524 18.3325 18.4435 13.5 18.4435C8.66751 18.4435 4.75 14.6524 4.75 9.9758C4.75 5.2992 8.66751 1.50806 13.5 1.50806C18.3325 1.50806 22.25 5.2992 22.25 9.9758Z"
          stroke="white"
          strokeWidth="1.5"
        />
        <path
          d="M13.5 18.5256L8.78568 23.2494C8.11053 23.9259 7.77296 24.2642 7.48708 24.3811C6.83565 24.6479 6.11302 24.4196 5.77035 23.839C5.61996 23.5841 5.5731 23.1244 5.47937 22.2051C5.42646 21.686 5.4 21.4264 5.31968 21.209C5.13988 20.7224 4.76207 20.3437 4.27637 20.1636C4.05942 20.0832 3.80041 20.0567 3.28236 20.0036C2.36483 19.9097 1.90607 19.8628 1.65172 19.712C1.07215 19.3687 0.844347 18.6446 1.11053 17.9919C1.22735 17.7054 1.56492 17.3672 2.24007 16.6906L5.31968 13.6049"
          stroke="white"
          strokeWidth="1.5"
        />
        <path
          d="M13.5 18.5256L18.2144 23.2494C18.8895 23.9259 19.227 24.2642 19.5129 24.3811C20.1644 24.6479 20.887 24.4196 21.2296 23.839C21.38 23.5841 21.4269 23.1244 21.5206 22.2051C21.5735 21.686 21.6 21.4264 21.6803 21.209C21.8601 20.7224 22.2379 20.3437 22.7236 20.1636C22.9406 20.0832 23.1996 20.0567 23.7176 20.0036C24.6351 19.9097 25.0939 19.8628 25.3482 19.712C25.9279 19.3687 26.1556 18.6446 25.8895 17.9919C25.7726 17.7054 25.4351 17.3672 24.7599 16.6906L21.6803 13.6049"
          stroke="white"
          strokeWidth="1.5"
        />
      </svg>
    ),
    friends:(
        <svg
          width="31"
          height="30"
          viewBox="0 0 31 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.6692 13.6783C15.0063 13.6783 16.9009 11.7356 16.9009 9.33916C16.9009 6.94271 15.0063 5 12.6692 5C10.3321 5 8.4375 6.94271 8.4375 9.33916C8.4375 11.7356 10.3321 13.6783 12.6692 13.6783Z"
            stroke="white"
            strokeWidth="1.5"
          />
          <path
            d="M19.0167 12.5936C20.7696 12.5936 22.1905 11.1365 22.1905 9.33921C22.1905 7.54188 20.7696 6.08484 19.0167 6.08484"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M12.6694 25.6111C16.7593 25.6111 20.0748 23.6684 20.0748 21.2719C20.0748 18.8754 16.7593 16.9327 12.6694 16.9327C8.57945 16.9327 5.26392 18.8754 5.26392 21.2719C5.26392 23.6684 8.57945 25.6111 12.6694 25.6111Z"
            stroke="white"
            strokeWidth="1.5"
          />
          <path
            d="M22.1908 18.0175C24.0466 18.4348 25.3646 19.4916 25.3646 20.7294C25.3646 21.846 24.2921 22.8154 22.7198 23.3008"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
    ),
    loading:(
        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 128 128" ><g><path d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z" fill="#ffffff"/><animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1000ms" repeatCount="indefinite"></animateTransform></g></svg>
    )
    ,
    energy:(
      <svg xmlns="http://www.w3.org/2000/svg" fill="#5056D2" width="24px" height="24px" viewBox="0 0 24 24"><path d="M7.493,22.862a1,1,0,0,0,1.244-.186l11-12A1,1,0,0,0,19,9H13.133l.859-6.876a1,1,0,0,0-1.8-.712l-8,11A1,1,0,0,0,5,14H9.612l-2.56,7.684A1,1,0,0,0,7.493,22.862ZM6.964,12l4.562-6.273-.518,4.149A1,1,0,0,0,12,11h4.727l-6.295,6.867,1.516-4.551A1,1,0,0,0,11,12Z"/></svg> )
  ,
  calendar:(
    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
    <path d="M3 10.312C3 5.93757 3.93757 5 8.312 5H15.688C20.0624 5 21 5.93757 21 10.312V15.688C21 20.0624 20.0624 21 15.688 21H8.312C3.93757 21 3 20.0624 3 15.688V10.312Z" stroke="#5056D2" stroke-width="2"/>
    <path d="M6 5L6 3" stroke="#5056D2" stroke-width="2" stroke-linecap="round"/>
    <path d="M18 5L18 3" stroke="#5056D2" stroke-width="2" stroke-linecap="round"/>
    <path d="M3.5 9H20.5" stroke="#5056D2" stroke-width="2" stroke-linecap="round"/>
    </svg>  
  )
,
    reward:(
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.1459 7.66696C11.5259 7.00727 11.7159 6.67743 12 6.67743C12.2841 6.67743 12.4741 7.00727 12.8541 7.66696L12.9524 7.83763C13.0603 8.02509 13.1143 8.11882 13.1985 8.18066C13.2827 8.24251 13.3875 8.26546 13.5972 8.31137L13.7881 8.35318C14.526 8.51475 14.895 8.59554 14.9828 8.86871C15.0706 9.14187 14.819 9.42651 14.316 9.9958L14.1858 10.1431C14.0429 10.3048 13.9714 10.3857 13.9392 10.4858C13.9071 10.5858 13.9179 10.6938 13.9395 10.9097L13.9592 11.1061C14.0352 11.8657 14.0733 12.2454 13.8435 12.4143C13.6136 12.5831 13.2682 12.4292 12.5773 12.1214L12.3986 12.0417C12.2022 11.9542 12.1041 11.9105 12 11.9105C11.8959 11.9105 11.7978 11.9542 11.6014 12.0417L11.4227 12.1214C10.7318 12.4292 10.3864 12.5831 10.1565 12.4143C9.92674 12.2454 9.96476 11.8657 10.0408 11.1061L10.0605 10.9097C10.0821 10.6938 10.0929 10.5858 10.0608 10.4858C10.0286 10.3857 9.95713 10.3048 9.81418 10.1431L9.68403 9.9958C9.18097 9.42651 8.92945 9.14187 9.01723 8.86871C9.10501 8.59554 9.47396 8.51475 10.2119 8.35318L10.4028 8.31137C10.6125 8.26546 10.7173 8.24251 10.8015 8.18066C10.8857 8.11882 10.9397 8.02509 11.0476 7.83763L11.1459 7.66696Z" stroke="#5056D2" stroke-width="1.5"/>
      <path d="M19 9.58065C19 13.3219 15.866 16.3548 12 16.3548C8.13401 16.3548 5 13.3219 5 9.58065C5 5.83937 8.13401 2.80646 12 2.80646C15.866 2.80646 19 5.83937 19 9.58065Z" stroke="#5056D2" stroke-width="1.5"/>
      <path d="M12 16.4205L8.22855 20.1995C7.68843 20.7408 7.41837 21.0113 7.18967 21.1049C6.66852 21.3183 6.09042 21.1357 5.81628 20.6712C5.69597 20.4673 5.65848 20.0995 5.5835 19.364C5.54117 18.9488 5.52 18.7411 5.45575 18.5672C5.31191 18.1779 5.00966 17.875 4.6211 17.7309C4.44754 17.6665 4.24033 17.6453 3.82589 17.6029C3.09187 17.5278 2.72486 17.4902 2.52138 17.3696C2.05772 17.095 1.87548 16.5157 2.08843 15.9935C2.18188 15.7643 2.45194 15.4938 2.99206 14.9525L5.45575 12.4839" stroke="#5056D2" stroke-width="1.5"/>
      <path d="M12 16.4205L15.7715 20.1995C16.3116 20.7408 16.5816 21.0113 16.8103 21.1049C17.3315 21.3183 17.9096 21.1357 18.1837 20.6712C18.304 20.4673 18.3415 20.0995 18.4165 19.364C18.4588 18.9488 18.48 18.7411 18.5442 18.5672C18.6881 18.1779 18.9903 17.875 19.3789 17.7309C19.5525 17.6665 19.7597 17.6453 20.1741 17.6029C20.9081 17.5278 21.2751 17.4902 21.4786 17.3696C21.9423 17.095 22.1245 16.5157 21.9116 15.9935C21.8181 15.7643 21.5481 15.4938 21.0079 14.9525L18.5442 12.4839" stroke="#5056D2" stroke-width="1.5"/>
      </svg>
      )
      ,
    info:(
      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
      <path d="M12 17V11" stroke="#5056D2" stroke-width="1.5" stroke-linecap="round"/>
      <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#5056D2"/>
      <path d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z" stroke="#5056D2" stroke-width="1.5"/>
      </svg>
      )
      ,
      copy:(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_8043_776)">
        <path d="M3.33335 13.3333C2.41669 13.3333 1.66669 12.5833 1.66669 11.6666V3.33329C1.66669 2.41663 2.41669 1.66663 3.33335 1.66663H11.6667C12.5834 1.66663 13.3334 2.41663 13.3334 3.33329M8.33335 6.66663H16.6667C17.5872 6.66663 18.3334 7.41282 18.3334 8.33329V16.6666C18.3334 17.5871 17.5872 18.3333 16.6667 18.3333H8.33335C7.41288 18.3333 6.66669 17.5871 6.66669 16.6666V8.33329C6.66669 7.41282 7.41288 6.66663 8.33335 6.66663Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_8043_776">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        
        )
        ,
      share:(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.33331 9.99996V16.6666C3.33331 17.1087 3.50891 17.5326 3.82147 17.8451C4.13403 18.1577 4.55795 18.3333 4.99998 18.3333H15C15.442 18.3333 15.8659 18.1577 16.1785 17.8451C16.4911 17.5326 16.6666 17.1087 16.6666 16.6666V9.99996M13.3333 4.99996L9.99998 1.66663M9.99998 1.66663L6.66665 4.99996M9.99998 1.66663V12.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        )
}

export default Icon