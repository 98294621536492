import React, { useContext } from "react";
import appContext from "utils/app-context";
import formatNumber from "utils/format-number";
import Icon from "./Icon";

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  showEnergyCount?: boolean;
  showBalance?: boolean;
  showEnergy?: boolean;
  className?: string;
}

const GameEnergy = (props: Props) => {
  const { showEnergyCount, className, showBalance, showEnergy } = props;
  const { user, t } = useContext(appContext);
  const text = t("homePage");
  if (!user) return null;
  const energyPercent = (user?.energy * 100) / user?.max_energy;
  
  return (
    <div className={`game-energy ${className || ""}`} {...props}>
      <div className="game-energy__contain">
        <img src="./img/energy.svg" className="game-energy__img" />
        <div className="game-energy__block">
          <p className="game-energy__title">{text.energy}</p>
          {/* <p className="game-energy__subtitle">100 нажатий/4ч.</p> */}
        </div>
        {showBalance && (
          <div className="main-avatar__block" style={{ marginLeft: "auto" }}>
            <img src="./img/coin.svg" />
            <p className="main-avatar__text">{formatNumber(user?.balance)}</p>
            {/* <FlipNumbers play height={14} width={11} color="white" perspective={100} numbers={formatNumber(user?.balance)} /> */}
          </div>
        )}
        {showEnergy && (
          <div className="main-avatar__block" style={{ marginLeft: "auto" }}>
            <p className="main-avatar__text">{formatNumber(user?.energy)}</p>
            <Icon icon="energy" height={24} width={24} />
          </div>
        )}
      </div>
      <div className="user-info__rank-progress">
        <span style={{ width: `${energyPercent}%` }}></span>
        {showEnergyCount && (
        <p
          className="game-energy__count"
          style={{ width: `${energyPercent}%`, marginTop: '10px', display: 'flex', alignItems: 'center', marginLeft: 'auto'}}
        >
          {user?.energy}
          {!showEnergy && <Icon icon='energy' height={24} width={24}/>}
        </p>
      )}
      </div>
    </div>
  );
};

export default GameEnergy;
