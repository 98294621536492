import React, { useContext, useState } from 'react'
import Icon from 'UI/Icon'
import PageWrapper from 'UI/PageWrapper'
import appContext from 'utils/app-context'
import formatNumber from 'utils/format-number'
import tg from 'utils/tg'
const tg_id = tg.initDataUnsafe.user?.id || 1891271272;
const inviteLink = (process.env.REACT_APP_SHARE_LINK?.replace('TG_ID',String(tg_id)) || process.env.REACT_APP_SHARE_LINK || '').replace("\"","")
const storage = process.env.REACT_APP_STORAGE_URL || '';

const Friends = () => {
  const {user,t} = useContext(appContext)
  const [copy, setcopy] = useState(false)
  const text = t('friendsPage')
  const copyShareLink = () => {
    setcopy(true)
    tg.HapticFeedback.notificationOccurred('success')
    navigator.clipboard.writeText(inviteLink)
    setTimeout(() => setcopy(false),1000)
  }
  const TelegramShare = () => {
      tg.openTelegramLink(`https://t.me/share/url?url=${inviteLink}&text=${encodeURIComponent(text.shareText.replace(/\\n/g, '%0A'))}`)
  }

  return (
    <PageWrapper >
      <div style={{display:'flex', flexDirection:'column', height:'100%'}}> 
      <div className="friends-task__text">
      <p className="friends-task__title">{text.title}</p>
     
    </div>
    <ul className="friends-list">
      <li className="friends-item">
        <div>
          <img src="./img/friends.svg" />
          <p className="friends-item__title">{text.title}</p>
          <p className="friends-item__number">{user?.friends}</p>
        </div>
      </li>
    </ul>
    <ul className="friends-list__second" style={{flexGrow:1}}>
      {user?.friends_list && user?.friends_list.map(({coins,name,photo},i) => {
        return (
          <li key={i} className="friends-item__second">
        <div className="friends-item__block_second">
          <img src={`${storage}/${photo}`} />
          <p className="friends-item__number_second">{name}</p>
        </div>
        <div className="friends-item__block_second">
          <p className="friends-item__number_second">+ {formatNumber(coins)}</p>
          <img src="./img/coin.svg" />
        </div>
      </li>
        )
      })}
    </ul>
    <div className="invite-buttons-container" style={{position:'sticky', bottom:10}}>
      <button className='copy-invite-link' onClick={copyShareLink}>
        <p>{copy ? text.linkCopied : inviteLink}</p>
      <Icon icon='copy'/>
      </button>
      <button className='develop-button share-invite-link' onClick={TelegramShare}>
        <Icon icon='share'/>
      </button>
    </div>
    </div>
    </PageWrapper>
  )
}

export default Friends