import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import routes from 'routes'
import appContext from 'utils/app-context'

const Navigation = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const currentLoc = routes.find(({route:{path}}) => location.pathname === path)
  const {t} = useContext(appContext)
  const titles = t('pageTitles')
  if (!currentLoc?.navBarIcon) return null
  
  return (
    <div className="sidebar">
      {routes.map(({route:{path},navBarIcon,titleKey}) => {
        if (!navBarIcon || !titleKey) return null
        const handleClick = () => navigate(path || '')
        const iscurrentRoute = location.pathname === path
        return (
          <button className={`sidebar__item ${iscurrentRoute && 'current'}`} key={path} onClick={handleClick}>
        {navBarIcon}
        <p className={`sidebar__text ${iscurrentRoute && 'sidebar__text_first'}`}>{titles[titleKey]}</p>
      </button>
        )
      })}
      {/* <button className="sidebar__item">
        
        <p className="sidebar__text">Игра</p>
      </button>
      <button className="sidebar__item">
        

        <p className="sidebar__text">Задания</p>
      </button>
      <button className="sidebar__item">
        
        <p className="sidebar__text">Развитие</p>
      </button>
      <button className="sidebar__item">
        
        <p className="sidebar__text">Друзья</p>
      </button> */}
    </div>
  )
}

export default Navigation