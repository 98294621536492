import {TranslationKeys} from "i18n";
import Entry from "pages/Entry/Entry";
import Friends from "pages/Friends/Friends";
import Game from "pages/Game/Game";
import Home from "pages/Home/Home";
import Shop from "pages/Shop/Shop";
import Tasks from "pages/Tasks/Tasks";
import { RouteObject } from "react-router-dom";
import Icon from "UI/Icon";

type Route = {
  route:RouteObject
  titleKey?:keyof TranslationKeys['pageTitles']
  navBarIcon?:JSX.Element
}

const routes:Route[] = [
    {
      route:{
        path: "/",
        element: <Home />,
      },
      titleKey:'home',
      navBarIcon:<Icon icon="home"/>,
    },
    {
      route:{
        path: "/game",
        element: <Game />,
      },
      navBarIcon:<Icon icon="game"/>,
      titleKey:'game',

    },
    {
      route:{
        path: "/tasks",
        element: <Tasks />,
      },
      navBarIcon:<Icon icon="tasks"/>,
      titleKey:'tasks',

    },
    {
      route:{
        path: "/shop",
        element: <Shop />,
      },
      navBarIcon:<Icon icon="shop"/>,
      titleKey:'upgrade',

    },
    {
      route:{
        path: "/friends",
        element: <Friends />,
      },
      navBarIcon:<Icon icon="friends"/>,
      titleKey:'friends',

    },
    {
      route:{
        path: "/entry",
        element: <Entry />,
      },
    }
  ]
  export default routes