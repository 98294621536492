import React, { useContext } from 'react';
import appContext from 'utils/app-context';
type Props = {
  close:() => void
}

const GameRules = ({ close }: Props) => {
  const {user} = useContext(appContext)
  return (
    <div className="popup" id="popup">
      <div className="popup__content">
        <span className="popup__close" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={close}> × </span>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          { user!.lang === 1 ?'Играйте и зарабатывайте токены проекта PROTON BOT Ecosystem!': 'Play and earn PROTON BOT Ecosystem project tokens!'}<br/><br/>
          { user!.lang === 1 ? 'Повышайте ваш уровень, что бы получать дополнительные токены за тап.': 'Increase your level to get additional tokens per tap.'} <br/>
          { user!.lang === 1 ? 'Выполняйте специальные задания и получите максимум очков!' : 'Complete special tasks and get maximum points!'} <br/>
          {user!.lang === 1 ?'Прокачивайте вашу энергию в магазине, что бы играть дольше!': 'Upgrade your energy in the store to play longer!'}<br/>
          { user!.lang === 1 ?'Копите очки и ждите аирдропа от PROTON!': 'Accumulate points and wait for PROTON' + "'" + 's Airdrop!'}<br/>
        </div>
      </div>
    </div>
  )
}

export default GameRules