import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import GameEnergy from 'UI/GameEnergy'
import PageWrapper from 'UI/PageWrapper'
import appContext from 'utils/app-context'
import formatNumber from 'utils/format-number'
import LangSelect from './components/LangSelect'
import Icon from 'UI/Icon'
import Farming from './components/Farming'
import PendingButton from 'UI/PendingButton'
const storage = process.env.REACT_APP_STORAGE_URL || '';

const Home = () => {
  const {user,updateUserInfo,t,setshowGameRules, updateUserLevel} = useContext(appContext)
  const text = t('homePage')
  const navigate = useNavigate()
  useEffect(() => {
    updateUserInfo()
  }, [])
  return (
    <PageWrapper>
      <div className="main-block">
      <LangSelect/>
      <img src="./img/logo.svg" className="main__logo" />
    </div>
    <div className="main-form">
      <div className="main-avatar">
        {/* TODO remove src */}
        <img src={`${storage}/${user?.avatar}`} className='avatar-photo'/>
        <p className="main-avatar__name">{user?.first_name}</p>
    
            <div style={{ display: 'flex', justifyContent: 'space-beetween', alignItems: 'center', width: '100%', marginBottom: "8px"}}>
              <div className="main-avatar__block" style={{display:'flex', flex:1, justifyContent: 'flex-start'}}>
                <img src="./img/star-cup.svg" />
                <p onClick={() => setshowGameRules(true)} className="main-avatar__text">{user?.level} {text.level}</p>
            </div>
            {user?.need_update_level ?<div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
              <button onClick={() => updateUserLevel()} className="main-statistics__button" style={{ height: "20px", width: 'auto', margin: 0 }}>{ user.lang === 1 ?'Новый уровень!': 'New level'}</button>
            </div> : ''}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-beetween', width: '100%'}}>
              <div className="main-avatar__block" style={{display:'flex', flex:1, justifyContent: 'flex-start'}}>
                <img src="./img/coin.svg" />
                <p className="main-avatar__text">{formatNumber(user?.balance)}</p>
              </div>
              <div className="main-avatar__block" style={{display:'flex', flex:1, justifyContent: 'flex-end'}}>
                <div style={{position:'relative'}}>
                  <Icon icon='calendar'/>
                  <p className='everyday-counter'>{user?.days_in_game}</p>
                </div>
                <p className="main-avatar__text">{text.gameDays}</p>
              </div>
            </div>
      
      </div>
      <GameEnergy style={{padding:0}} showEnergy/>
      <Farming/>
      
      {!user?.energy_seconds
      ? <button className="main-statistics__button" onClick={() =>  navigate('/game')}>{text.startGame}</button>
      : <PendingButton style={{marginTop:20}} text={text.energyRecovery} timeStart={user?.energy_seconds || 500} callback={updateUserInfo}/> 
      }
    </div>
    </PageWrapper>
  )
}

export default Home