import React, { useEffect, useState } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import useAppData from "./hooks/use-app-data";
import routes from "./routes";
import { AnimatePresence } from "framer-motion";
import tg from "utils/tg";
import Navigation from "UI/Navigation";
import GameRules from "UI/GameRules";
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isMobileDevice } from "services/check-mobile";
import IsNotMobile from "UI/IsNotMobile";
import { initGA, logPageView } from "analitycs";
import Onboarding from "./UI/Onboarding";

function App() {
  const { AppContext, AppContextValue } = useAppData();
  const element = useRoutes(routes.map(({ route }) => route));
  const location = useLocation();
  const [isMobile, setIsMobile] = useState<boolean | null>(null);
  const [showOnboarding, setShowOnboarding] = useState<boolean>(
    !hasCompletedOnboarding()
  );

  useEffect(() => {
    initGA("G-V6TY2L6VV2");
    logPageView(window.location.pathname);
  }, []);

  useEffect(() => {
    const checkDevice = async () => {
      const result = await isMobileDevice();
      setIsMobile(result);
    };

    checkDevice();
  }, []);

  useEffect(() => {
    tg.expand();
    tg.disableVerticalSwipes();
  }, []);

  const handleOnboardingFinish = () => {
    setOnboardingCompleted();
    setShowOnboarding(false);
  };

  function hasCompletedOnboarding(): boolean {
    return localStorage.getItem("onboardingCompleted") === "true";
  }

  function setOnboardingCompleted(): void {
    localStorage.setItem("onboardingCompleted", "true");
  }

  if (!element) return null;

  const { showGameRules, setshowGameRules } = AppContextValue;

  if (!isMobile) {
    return (
      <AppContext.Provider value={AppContextValue}>
        <AnimatePresence mode="sync">
          <IsNotMobile />
        </AnimatePresence>
      </AppContext.Provider>
    );
  } else {
    return (
      <AppContext.Provider value={AppContextValue}>
        <AnimatePresence mode="sync">
          {showOnboarding ? (
            <Onboarding onFinish={handleOnboardingFinish} />
          ) : (
            React.cloneElement(element, { key: location.pathname })
          )}
        </AnimatePresence>
        {!showOnboarding && <Navigation />}
        {!showOnboarding && showGameRules && (
          <GameRules close={() => setshowGameRules(false)} />
        )}
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          draggableDirection="y"
          pauseOnHover
          theme="dark"
          transition={Bounce}
        />
      </AppContext.Provider>
    );
  }
}

export default App;
