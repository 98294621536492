import React from 'react';


const IsNotMobile = () => {
  return (
      <div className="qr-container" style={{display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <h2>Play on your mobile</h2>
        <img style={{width: '200px', height: '200px', borderRadius: '20px'}} src="./img/qrcode.svg" />
        <p>@Proton_betaversion_bot</p>
      </div>
  );
}

export default IsNotMobile;