import { useState, useEffect } from 'react';
import UserData from 'types/user-data';

export default (userData: UserData | null) => {
    const [seconds, setSeconds] = useState(0);
    const [percent, setPercent] = useState(0);
    const [coins, setCoins] = useState(0);

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        // Проверка, если userData доступен и статус фарминга - в процессе
        if (userData && userData.farming_status === 1) {
            const { farming_seconds, farming_percent, farming_coins, farming_period } = userData;
            
            setSeconds(farming_seconds);
            setPercent(farming_percent);
            setCoins(farming_coins);

            interval = setInterval(() => {
                setSeconds((prev) => {
                    if (prev > 0) {
                        return prev - 1;
                    } else {
                        clearInterval(interval!);
                        return 0;
                    }
                });

                setPercent((prev) => {
                    const newPercent = prev + (100 / farming_period);
                    return newPercent > 100 ? 100 : newPercent;
                });

                setCoins((prev) => prev + (farming_coins / farming_period));
            }, 1000);
        }

        // Если статус завершен, установить прогресс на 100
        if (userData && userData.farming_status === 2) {
            setPercent(100);
        }

        // Очистка интервала при завершении или размонтировании компонента
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [userData]);

    return { seconds, percent, coins };
};
