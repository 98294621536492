import React, { useContext, useState } from "react";
import appContext from "utils/app-context";

export function Onboarding({ onFinish }: { onFinish: () => void }) {
  const { t } = useContext(appContext);
  const [step, setStep] = useState(0);
  const text = t("onboarding");

  const steps = [
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h3 style={{ fontSize: 20, height: 22 }}>{text.title}</h3>
      <div>
        <img style={{ marginLeft: -20 }} height={250} src={`./img/png/1.png`} />
      </div>
      <p style={{ fontSize: 18, marginTop: "auto" }}>{text.first}</p>
    </div>,
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h3 style={{ fontSize: 20, height: 22 }}></h3>
      <div>
        <img height={250} src={`./img/png/2.png`} />
      </div>
      <p style={{ fontSize: 18, marginTop: "auto" }}>{text.second}</p>
    </div>,
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h3 style={{ fontSize: 20, height: 22 }}></h3>
      <div>
        <img height={250} src={`./img/png/3.png`} />
      </div>
      <p style={{ fontSize: 18, marginTop: "auto" }}>{text.third}</p>
    </div>,
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h3 style={{ fontSize: 20, height: 22 }}></h3>
      <div>
        <img height={250} src={`./img/png/4.png`} />
      </div>
      <p style={{ fontSize: 18, marginTop: "auto" }}>{text.fourth}</p>
    </div>,
  ];

  const handleNext = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    } else {
      onFinish();
    }
  };

  const handlePrev = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  return (
    <div
      className="page-wrapper"
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        height: "100vh",
        padding: 20,
        boxSizing: "border-box",
      }}
    >
      {steps[step]}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "auto",
        }}
      >
        <button
          onClick={handlePrev}
          style={{
            padding: "10px 20px",
            visibility: step > 0 ? "visible" : "hidden",
            backgroundColor: "transparent",
            border: "none",
            color: "White",
          }}
        >
          {step > 0 ? (
            <img
              height={40}
              style={{ transform: "rotateY(180deg)" }}
              src="./img/chevron-right-white.svg"
              alt=""
            />
          ) : (
            ""
          )}
        </button>
        <button
          onClick={handleNext}
          style={{
            padding: "10px 20px",
            backgroundColor: "transparent",
            border: "none",
            color: "White",
            fontSize: 18,
          }}
        >
          {step < steps.length - 1 ? (
            <img height={40} src="./img/chevron-right-white.svg" alt="" />
          ) : (
            text.finish
          )}
        </button>
      </div>
    </div>
  );
}

export default Onboarding;
