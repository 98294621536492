import claimFarming from "api/claim-farming";
import startFarming from "api/start-farming";
import useFarming from "hooks/use-farming";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import Icon from "UI/Icon";
import appContext from "utils/app-context";
import formatTime from "utils/format-time";

const Farming = () => {
  const { user, updateUserInfo ,t} = useContext(appContext);
  const {percent,seconds} = useFarming(user)
 
  const text = t('homePage')
  const handleFarmingButton = async () => {
    let res;
    switch (user?.farming_status) {
      case 0:
        res = await startFarming();
        break;

      case 2:
        res = await claimFarming();
      if(res?.alert) toast(res?.alert,
        {icon:<img src="./img/coin.svg" />}
      )
        break;

      default:
        break;
    }
    updateUserInfo();
  };

  if (!user) return null;
  const { farming_status } = user;

  return (
    <div className="main-statistics">
      <div className="main-statistics__contain">
        <Icon icon="reward" />
        <p className="main-statistics__title">{text.hourlyReward}</p>
      </div>
      {farming_status === 1 && (
        <p className="game-energy__count" style={{ width: `${percent}%` }}>
          {formatTime(seconds)}
        </p>
      )}
      <div className="user-info__rank-progress">
        <span style={{ width: `${percent}%` }}></span>
      </div>
      {farming_status !== 1 && (
        <button
          className={`main-statistics__button ${farming_status !== 0 && "main-farming-btn"}`}
          onClick={handleFarmingButton}
        >
          {farming_status === 2 ? text.claimFarming : text.startFarming}
        </button>
      )}
    </div>
  );
};


export default Farming;
