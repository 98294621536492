import { motion } from "framer-motion";
import React from "react";
import { useNavigationType } from "react-router-dom";

type Props = {
  children?: any;
  loading?:boolean;
  onAnimationEnd?:() => void;
};

const transition = {
  duration: 0.1,
};

const PageWrapper = (props: Props) => {
  const {children,onAnimationEnd} = props
  const navigationType = useNavigationType();

  const isGoingBack = navigationType === 'POP'

  const animationProps ={
    initial:{
      opacity: 0, translateX: isGoingBack ? -50 : 50
    },
    animate:{ opacity: 1, translateX: 0, transition: transition },
    exit:{ opacity: 0, translateX: isGoingBack ? 50 : -50, transition: transition }
  }

  return (
    <motion.div
      onAnimationComplete={onAnimationEnd}
      id={window.location.pathname}
      className="page-wrapper"
      {...animationProps}
    >
      
      {
      children
    }</motion.div>
  );
};

export default PageWrapper;
