import { logEvent } from "analitycs";
import doneTask from "api/done-task";
import getTasks from "api/get-tasks";
import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Task from "types/task";
import PageWrapper from "UI/PageWrapper";
import UserProfile from "UI/UserProfile";
import appContext from "utils/app-context";
import formatNumber from "utils/format-number";
import tg from "utils/tg";
const storage = process.env.REACT_APP_STORAGE_URL || '';

const Tasks = () => {
  const {t,setUser,user} = useContext(appContext)
  const text = t('taskPage')
  const [tasks, setTasks] = useState<Task[] | null>(null);
  const [pendingTasks, setPendingTasks] = useState<string[]>([]);
  const updateTasks = async () => {
    const result = await getTasks();
    if (result) {
      setTasks(result.tasks.sort((TaskA, TaskB) => {
        if (TaskA.status === TaskB.status) {
          return 0;
        }
        if (TaskA.status === 2) {
          return -1;
        }
        if (TaskB.status === 2) {
          return 1;
        }
        return TaskA.status - TaskB.status; // Для сортировки 0 и 1
      }));
    }
  };

  const claimTask = (taskID:string,url:string) => {
    setPendingTasks([...pendingTasks,taskID])
    if (url) {
      try {
        tg.openTelegramLink(url)
      } catch (_) {
        tg.openLink(url)
      }
    }
    const HandleTask = async () => {
      setPendingTasks(pendingTasks.filter(id => id !== taskID))
        const result = await doneTask(taskID)
        const err = () => tg.showAlert(text.defaultError)
        switch (result?.status) {
            case 2:
              if (!url) toast(text.taskNotDone,{autoClose:1000,hideProgressBar:true,type:'info'})
            break;
      }
      console.log('result?.balance', result?.balance);
        if(result?.balance && user) setUser({...user,balance:result?.balance})
        if(!result) err()
        updateTasks()
    } 

    setTimeout(HandleTask,3000)
  }

  useEffect(() => {
    updateTasks();
  }, []);
  return (
    <PageWrapper>
      <div className="tasks-contain">
      <UserProfile/>
      </div>
      <div className="task__text">
        <p className="task__title">{text.title}</p>
        <p className="task__subtitle" dangerouslySetInnerHTML={{__html:text.desc.replace('#',String(tasks?.length))}}>
        </p>
      </div>
      <ul className="task-list">
        {tasks &&
          tasks.map(({ id, reward, title, status, url, img }) => { 
            const handleClick = () => {
              logEvent('Task Interaction', 'Click', title);
              if(status === 0 || status === 2) claimTask(id,url)
            }
            const isPending = pendingTasks.findIndex(tID => tID === id) !== -1
            // const taskStatusImageUrl = isPending ? 'loader.svg' : status ? 'circle-check.svg' : 'chevron-right.svg'
            let taskStatusImageUrl = isPending ? 'loader.svg' : 'chevron-right.svg'

            switch (status) {
              case 1:
                taskStatusImageUrl = 'circle-check.svg'
                break;
            
              case 2:
                taskStatusImageUrl = 'clock.svg'
                break;
            }

            return (
              <motion.li layout className="task-item" style={{alignItems: 'center'}} onClick={handleClick} key={id}>
                <div>
                  <p className="task-item__title">{title}</p>
                  <div className="task-item__block">
                    <img src="./img/coin.svg" style={{marginRight:7}}/>
                    <p className="task-item__number">
                      + {formatNumber(reward)}
                    </p>
                  </div>
                </div>
                {img ? <img src={`${storage}/${img}`} style={{ marginLeft: 'auto', marginRight: 10, height: '30px' }} />: ""}
                <img className={`${isPending && 'loading'}`} src={`./img/${taskStatusImageUrl}`} />
              </motion.li>
            );
          })}
      </ul>
    </PageWrapper>
  );
};

export default Tasks;
