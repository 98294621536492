import MobileDetect from 'mobile-detect';

export const isMobileDevice = async () => {
  const md = new MobileDetect(window.navigator.userAgent);
  const isMobileAgent: boolean = !!md.mobile();
  const isMobile = /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(navigator.userAgent);
  const isMobileScreen: boolean = window.innerWidth <= 768;
  const isTouchDevice: boolean = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

  return isMobileAgent && isMobileScreen && isTouchDevice && isMobile;
};