import { motion } from 'framer-motion';
import React, { useState, useEffect, useRef } from 'react';
import GameParticle from './components/GameParticle';
import UserData from 'types/user-data';

type Props = {
  gameState: boolean;
  skin:UserData['skin']
  level:UserData['level']
  handleTap: React.PointerEventHandler<HTMLDivElement> | undefined;
};
const colors = ['#47046B', '#D70FA8', '#2A6BDF'];
const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };

// Обновленный тип частицы с заданными размерами
type Particle = {
  id: number;
  x: number;
  y: number;
  directionX: number;
  directionY: number;
  speed: number;
  exploding: boolean;
  width: number; // Ширина частицы
  height: number; // Высота частицы
  color:string
  numElectrons:number
};

const MAX_PARTICLES = 15; // Максимальное количество частиц до взрыва

const GameField = ({ gameState, handleTap,skin ,level}: Props) => {
  const [particles, setParticles] = useState<Particle[]>([]);
  const [speedMultiplier, setSpeedMultiplier] = useState(1);
  const [targetSpeedMultiplier, setTargetSpeedMultiplier] = useState(1);
  const gameFieldRef = useRef<HTMLDivElement>(null);

  const centralImageSize = 100; // Размер центральной картинки

  const createParticle = (width: number, height: number) => {
    const gameField = gameFieldRef.current?.getBoundingClientRect();
    if (!gameField) return;

    const newParticle: Particle = {
      id: particles.length + 1,
      x: Math.random() * (gameField.width - width), // Учёт ширины
      y: Math.random() * (gameField.height - height), // Учёт высоты
      directionX: Math.random() * 2 - 1,
      directionY: Math.random() * 2 - 1,
      speed: 2,
      exploding: false,
      width: width, // Устанавливаем заданную ширину
      height: height, // Устанавливаем заданную высоту
      color:getRandomColor(),
      numElectrons:Math.floor(Math.random() * 5) + 1
    };

    setParticles(prevParticles => {
      if (prevParticles.length >= MAX_PARTICLES) {
        triggerExplosion();
        return prevParticles; // Не добавляем новых частиц, если происходит взрыв
      }
      return [...prevParticles, newParticle];
    });
  };

  const triggerExplosion = () => {
    setParticles(prevParticles =>
      prevParticles.map(p => ({
        ...p,
        exploding: true,
      }))
    );

    setTimeout(() => {
        setParticles(prevParticles =>
            prevParticles.filter(({exploding}) => !exploding) )
      // Очищаем частицы после взрыва
    }, 1000); // Время взрыва (1 секунда)
  };

  const updateParticles = () => {
    const gameField = gameFieldRef.current?.getBoundingClientRect();
    if (!gameField) return;

    const centralX = gameField.width / 2;
    const centralY = gameField.height / 2;

    setParticles(prevParticles =>
      prevParticles.map(p => {
        if (p.exploding) return p; // Если частица взрывается, её не обновляем

        let newX = p.x + p.directionX * p.speed * speedMultiplier;
        let newY = p.y + p.directionY * p.speed * speedMultiplier;

        // Коррекция направления частиц для предотвращения выхода за границы
        if (newX < 0) {
          p.directionX = Math.abs(p.directionX);
        } else if (newX + p.width > gameField.width) {
          p.directionX = -Math.abs(p.directionX);
        }

        if (newY < 0) {
          p.directionY = Math.abs(p.directionY);
        } else if (newY + p.height > gameField.height) {
          p.directionY = -Math.abs(p.directionY);
        }

        // Избегаем центральную картинку
        const distX = newX + p.width / 2 - centralX;
        const distY = newY + p.height / 2 - centralY;
        const distanceToCenter = Math.sqrt(distX * distX + distY * distY);

        if (distanceToCenter < centralImageSize / 2 + Math.max(p.width, p.height) / 2) {
          p.directionX += distX > 0 ? 0.05 : -0.05;
          p.directionY += distY > 0 ? 0.05 : -0.05;
        }

        return { ...p, x: newX, y: newY };
      })
    );

    // Обрабатываем отталкивание частиц друг от друга
    setParticles(prevParticles =>
      prevParticles.map(p1 => {
        prevParticles.forEach(p2 => {
          if (p1.id !== p2.id) {
            const distX = p1.x - p2.x;
            const distY = p1.y - p2.y;
            const distanceBetweenParticles = Math.sqrt(distX * distX + distY * distY);

            // Проверка на столкновение с учётом размеров
            if (distanceBetweenParticles < p1.width / 2 + p2.width / 2) {
              p1.directionX += distX > 0 ? 0.05 : -0.05;
              p1.directionY += distY > 0 ? 0.05 : -0.05;
              p2.directionX -= distX > 0 ? 0.05 : -0.05;
              p2.directionY -= distY > 0 ? 0.05 : -0.05;
            }
          }
        });
        return p1;
      })
    );
  };

  const handleSpeedImpulse = () => {
    setTargetSpeedMultiplier(8); // Устанавливаем ускорение
  };

  useEffect(() => {
    const interval = setInterval(updateParticles, 16);
    return () => clearInterval(interval);
  }, [particles, speedMultiplier]);

  useEffect(() => {
    const smoothSpeedReduction = () => {
      if (speedMultiplier !== targetSpeedMultiplier) {
        setSpeedMultiplier(prev =>
          prev + (targetSpeedMultiplier - prev) * 0.1 // Плавное изменение скорости
        );
      }
    };

    const interval = setInterval(smoothSpeedReduction, 16);
    return () => clearInterval(interval);
  }, [speedMultiplier, targetSpeedMultiplier]);

  const handlePointerUp = () => {
    setTargetSpeedMultiplier(1); // Возвращаем скорость обратно к базовой
  };
  useEffect(() => {
    if (!gameState) {
      setParticles([])
    }
  }, [gameState])
  return (
    <motion.div
      ref={gameFieldRef}
      className='game-field'
      style={{ position: 'relative', width: '100%', height: '100%' }}
      onPointerDown={(e) => {
        if (!gameState) return
        handleTap?.(e);
        createParticle(54, 54); // Задайте ширину и высоту вашей частицы
        handleSpeedImpulse(); // Применяем импульс
      }}
      onPointerUp={handlePointerUp} // Плавно возвращаем скорость к норме
    >
     
      <motion.div
        className={`game-wall ${gameState && 'active-game'}`}
        style={{
          width: centralImageSize,
          height: centralImageSize,
        }}
      >
       
        <motion.img
          src={skin || `./img/proton/${1}.svg`}
          className={``}
        />
         <motion.img
          src={`./img/proton-dots/${level}.svg`}
          className={`proton-dot`}
        />
      </motion.div>
      
      {particles.map(p => (
        <motion.div
          key={p.id}
          className='particle'
          initial={{
            opacity:0,
            scale:0.5
             }}
        animate={{
            scale: p.exploding ? [1, 2, 0] : 1, // Взрыв: Увеличиваем частицы, затем исчезают
            opacity: p.exploding ? [1, 0.5, 0] : 1,
          }}
          transition={{
            duration: p.exploding ? 1 : 0, // Длительность анимации взрыва
            ease: 'easeOut',
          }}
          style={{
            position: 'absolute',
            left: p.x,
            top: p.y,
            width: p.width, // Используем ширину частицы
            height: p.height, // Используем высоту частицы
          }}
        >
          <GameParticle key={p.id} color={p.color} numElectrons={p.numElectrons}/>
        </motion.div>
      ))}
    </motion.div>
  );
};

export default GameField;
